/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateExperienceTypeResult } from '../model/createExperienceTypeResult';
import { DeleteExperienceTypeCommand } from '../model/deleteExperienceTypeCommand';
import { ExperienceTypeEnum } from '../model/experienceTypeEnum';
import { ExperienceTypeModel } from '../model/experienceTypeModel';
import { MoveExperienceTypeCommand } from '../model/moveExperienceTypeCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ExperienceTypeService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param experienceId 
     * @param name 
     * @param description 
     * @param experienceTypeEnum 
     * @param experienceTypeNumber 
     * @param moduleIds 
     * @param model 
     * @param image 
     * @param imageName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experienceTypeCreatePostForm(experienceId?: string, name?: string, description?: string, experienceTypeEnum?: ExperienceTypeEnum, experienceTypeNumber?: string, moduleIds?: Array<string>, model?: Blob, image?: string, imageName?: string, observe?: 'body', reportProgress?: boolean): Observable<CreateExperienceTypeResult>;
    public experienceTypeCreatePostForm(experienceId?: string, name?: string, description?: string, experienceTypeEnum?: ExperienceTypeEnum, experienceTypeNumber?: string, moduleIds?: Array<string>, model?: Blob, image?: string, imageName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateExperienceTypeResult>>;
    public experienceTypeCreatePostForm(experienceId?: string, name?: string, description?: string, experienceTypeEnum?: ExperienceTypeEnum, experienceTypeNumber?: string, moduleIds?: Array<string>, model?: Blob, image?: string, imageName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateExperienceTypeResult>>;
    public experienceTypeCreatePostForm(experienceId?: string, name?: string, description?: string, experienceTypeEnum?: ExperienceTypeEnum, experienceTypeNumber?: string, moduleIds?: Array<string>, model?: Blob, image?: string, imageName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (experienceId !== undefined) {
            formParams = formParams.append('ExperienceId', <any>experienceId) as any || formParams;
        }
        if (name !== undefined) {
            formParams = formParams.append('Name', <any>name) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('Description', <any>description) as any || formParams;
        }
        if (experienceTypeEnum !== undefined) {
            formParams = formParams.append('ExperienceTypeEnum', <any>experienceTypeEnum) as any || formParams;
        }
        if (experienceTypeNumber !== undefined) {
            formParams = formParams.append('ExperienceTypeNumber', <any>experienceTypeNumber) as any || formParams;
        }
        if (moduleIds) {
            moduleIds.forEach((element) => {
                formParams = formParams.append('ModuleIds', <any>element) as any || formParams;
            })
        }
        if (model !== undefined) {
            formParams = formParams.append('Model', <any>model) as any || formParams;
        }
        if (image !== undefined) {
            formParams = formParams.append('Image', <any>image) as any || formParams;
        }
        if (imageName !== undefined) {
            formParams = formParams.append('ImageName', <any>imageName) as any || formParams;
        }

        return this.httpClient.request<CreateExperienceTypeResult>('post',`${this.basePath}/ExperienceType/Create`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experienceTypeDeleteDelete(body?: DeleteExperienceTypeCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public experienceTypeDeleteDelete(body?: DeleteExperienceTypeCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public experienceTypeDeleteDelete(body?: DeleteExperienceTypeCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public experienceTypeDeleteDelete(body?: DeleteExperienceTypeCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('delete',`${this.basePath}/ExperienceType/Delete`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param experienceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experienceTypeGetCollectionGet(experienceId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ExperienceTypeModel>>;
    public experienceTypeGetCollectionGet(experienceId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ExperienceTypeModel>>>;
    public experienceTypeGetCollectionGet(experienceId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ExperienceTypeModel>>>;
    public experienceTypeGetCollectionGet(experienceId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (experienceId !== undefined && experienceId !== null) {
            queryParameters = queryParameters.set('ExperienceId', <any>experienceId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ExperienceTypeModel>>('get',`${this.basePath}/ExperienceType/GetCollection`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experienceTypeGetSingleGet(id?: string, observe?: 'body', reportProgress?: boolean): Observable<ExperienceTypeModel>;
    public experienceTypeGetSingleGet(id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExperienceTypeModel>>;
    public experienceTypeGetSingleGet(id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExperienceTypeModel>>;
    public experienceTypeGetSingleGet(id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('Id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ExperienceTypeModel>('get',`${this.basePath}/ExperienceType/GetSingle`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experienceTypeMovePut(body?: MoveExperienceTypeCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public experienceTypeMovePut(body?: MoveExperienceTypeCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public experienceTypeMovePut(body?: MoveExperienceTypeCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public experienceTypeMovePut(body?: MoveExperienceTypeCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/ExperienceType/Move`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param name 
     * @param description 
     * @param experienceTypeNumber 
     * @param model 
     * @param moduleIds 
     * @param image 
     * @param imageName 
     * @param imageDeleted 
     * @param modelDeleted 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experienceTypeUpdatePutForm(id?: string, name?: string, description?: string, experienceTypeNumber?: string, model?: Blob, moduleIds?: Array<string>, image?: string, imageName?: string, imageDeleted?: boolean, modelDeleted?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public experienceTypeUpdatePutForm(id?: string, name?: string, description?: string, experienceTypeNumber?: string, model?: Blob, moduleIds?: Array<string>, image?: string, imageName?: string, imageDeleted?: boolean, modelDeleted?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public experienceTypeUpdatePutForm(id?: string, name?: string, description?: string, experienceTypeNumber?: string, model?: Blob, moduleIds?: Array<string>, image?: string, imageName?: string, imageDeleted?: boolean, modelDeleted?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public experienceTypeUpdatePutForm(id?: string, name?: string, description?: string, experienceTypeNumber?: string, model?: Blob, moduleIds?: Array<string>, image?: string, imageName?: string, imageDeleted?: boolean, modelDeleted?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (id !== undefined) {
            formParams = formParams.append('Id', <any>id) as any || formParams;
        }
        if (name !== undefined) {
            formParams = formParams.append('Name', <any>name) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('Description', <any>description) as any || formParams;
        }
        if (experienceTypeNumber !== undefined) {
            formParams = formParams.append('ExperienceTypeNumber', <any>experienceTypeNumber) as any || formParams;
        }
        if (model !== undefined) {
            formParams = formParams.append('Model', <any>model) as any || formParams;
        }
        if (moduleIds) {
            moduleIds.forEach((element) => {
                formParams = formParams.append('ModuleIds', <any>element) as any || formParams;
            })
        }
        if (image !== undefined) {
            formParams = formParams.append('Image', <any>image) as any || formParams;
        }
        if (imageName !== undefined) {
            formParams = formParams.append('ImageName', <any>imageName) as any || formParams;
        }
        if (imageDeleted !== undefined) {
            formParams = formParams.append('ImageDeleted', <any>imageDeleted) as any || formParams;
        }
        if (modelDeleted !== undefined) {
            formParams = formParams.append('ModelDeleted', <any>modelDeleted) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/ExperienceType/Update`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
