import { Component, inject, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './layout/shell/shell.component';
import { NotAuthorizedComponent } from './shared/components/not-authorized/not-authorized.component';
import { ErrorService } from './shared/services/error.service';

@Component({
  standalone: true,
  imports: [ RouterModule,ShellComponent,NotAuthorizedComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',

})
export class AppComponent {
  errorService=inject(ErrorService)
}
