import { Route } from '@angular/router';
import { RouteConstants } from './shared/constants/route.constants';
import { MsalGuard } from '@azure/msal-angular';
import { roleGuard } from './shared/guards/role.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: `${RouteConstants.DASHBOARD}`,
    pathMatch: 'full',
  },
  {
    path: `${RouteConstants.DASHBOARD}`,
    loadComponent: () => {
      return import('./layout/dashboard/dashboard.component').then(
        (m) => m.DashboardComponent
      );
    },
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: `${RouteConstants.USER_MANAGEMENT}`,
    loadComponent: () =>
      import(
        './admin/components/user-management/user-management.component'
      ).then((x) => x.UserManagementComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: `${RouteConstants.USER_MANAGEMENT}/:uid`,
    loadComponent: () =>
      import(
        './admin/components/user-management/user-details-page/user-details-page.component'
      ).then((x) => x.UserDetailsPageComponent),
    data: undefined,
    canActivate: [MsalGuard,],
  },
  {
    path: `${RouteConstants.LICENCE_MANAGEMENT}`,
    loadComponent: () =>
      import(
        './admin/components/licence-management/licence-management.component'
      ).then((x) => x.LicenceManagementComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: `${RouteConstants.APPROVALS}`,
    loadComponent: () =>
      import('./admin/components/approvals/approvals.component').then(
        (x) => x.ApprovalsComponent
      ),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: `${RouteConstants.COHORT_MANAGEMENT}`,
    loadComponent: () =>
      import(
        './admin/components/cohort-management/cohort-management.component'
      ).then((x) => x.CohortManagementComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: `${RouteConstants.COHORT_MANAGEMENT}/:uid`,
    loadComponent: () =>
      import(
        './admin/components/cohort-management/cohort-details-page/cohort-details-page.component'
      ).then((x) => x.CohortDetailsPageComponent),
      canActivate: [MsalGuard],
    },
  {
    path: `${RouteConstants.WING_MANAGEMENT}`,
    loadComponent: () =>
      import(
        './admin/components/wing-management/wing-management.component'
      ).then((x) => x.WingManagementComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: `${RouteConstants.WING_MANAGEMENT}/:uid`,
    loadComponent: () =>
      import(
        './admin/components/wing-management/wing-details-page/wing-details-page.component'
      ).then((x) => x.WingDetailsPageComponent),
      canActivate: [MsalGuard],
    },
  {
    path: `${RouteConstants.STAFF}`,
    loadComponent: () =>
      import('./admin/components/staff/staff.component').then(
        (x) => x.StaffComponent
      ),
    canActivate: [MsalGuard,roleGuard],
  },
  {
    path: `${RouteConstants.TRAINING_PROGRAM}/:uid`,
    loadComponent: () =>
      import(
        './shared/components/training-program/training-program.component'
      ).then((x) => x.TrainingProgramComponent),
      canActivate: [MsalGuard],
    },
  {
    path: `${RouteConstants.SQUADRON_MANAGEMENT}`,
    loadComponent: () =>
      import(
        './admin/components/squadrons-management/squadrons-management.component'
      ).then((x) => x.SquadronManagementComponent),
    canActivate: [MsalGuard, roleGuard],
  },

  {
    path: `${RouteConstants.SQUADRON_MANAGEMENT}/:uid`,
    loadComponent: () =>
      import(
        './admin/components/squadrons-management/squadrons-details-page/squadrons-details-page.component'
      ).then((x) => x.SquadronsDetailsPageComponent),
      canActivate: [MsalGuard],
    },
  {
    path: `${RouteConstants.TRAINING_MANAGEMENT}`,
    loadComponent: () =>
      import(
        './admin/components/training-management/training-management.component'
      ).then((x) => x.TrainingManagementComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: `${RouteConstants.TRAINING_MANAGEMENT}/${RouteConstants.EXPERIENCE}`,
    loadComponent: () =>
      import(
        './admin/components/training-management/experience-management/experience-management.component'
      ).then((x) => x.ExperienceViewComponent),
      canActivate: [MsalGuard,roleGuard],
    },
  {
    path: `${RouteConstants.DOWNLOAD_EXPERIENCE}`,
    loadComponent: () =>
      import(
        './admin/components/download-experience/download-experience.component'
      ).then((x) => x.DownloadExperienceComponent),
    canActivate: [MsalGuard,roleGuard],
  },
  {
    path: `${RouteConstants.INVOICES}`,
    loadComponent: () =>
      import('./admin/components/invoices/invoices.component').then(
        (x) => x.InvoicesComponent
      ),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: `${RouteConstants.NOTIFICATIONS}`,
    loadComponent: () =>
      import('./shared/components/notifications/notifications.component').then(
        (x) => x.NotificationsComponent
      ),
    canActivate: [MsalGuard],
  },
  {
    path: `${RouteConstants.HELP_MANAGEMENT}`,
    loadComponent: () =>
      import('./shared/components/help/help-management.component').then(
        (x) => x.HelpManagementComponent
      ),
    canActivate: [MsalGuard],
  },
  {
    path: `not-authorized`,
    loadComponent: () =>
      import('./shared/components/not-authorized/not-authorized.component').then(
        (x) => x.NotAuthorizedComponent
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'error-test',
    loadComponent: () =>
      import('./test/error-test/error-test.component').then(
        (x) => x.ErrorTestComponent
      ),
    canActivate: [MsalGuard],
  },
  {
    path: '**',
    redirectTo: `${RouteConstants.DASHBOARD}`,
  },
];
