import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
  InteractionType,
} from '@azure/msal-browser';
import { environment } from 'apps/portal/src/environments/environment';
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId, // This is the ONLY mandatory field that you need to supply.
      authority: environment.msalConfig.auth.authority, // Defaults to "https://login.microsoftonline.com/common"
      redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
      postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: !environment.production,
        loggerCallback(logLevel: LogLevel, message: string) {
          if (!environment.production) {
          }
        },
      },
    },
  });
}

export function MsalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect, //can also be popup
    authRequest: {
      scopes: [...environment.apiConfig.scopes],
      prompt: 'consent'    },

  };
}

export function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<
    string,
    Array<string | ProtectedResourceScopes> | null
  >();

  protectedResourceMap.set(`${environment.apiUrl}`, [
    `${environment.serviceClientId}`,
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: protectedResourceMap,
  };
}
