/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CompletionLevelType } from '../model/completionLevelType';
import { GetActiveUsersResultListResponse } from '../model/getActiveUsersResultListResponse';
import { GetBestCadetsResultResponse } from '../model/getBestCadetsResultResponse';
import { GetCadetOverviewResultResponse } from '../model/getCadetOverviewResultResponse';
import { GetCompletionDetailsResultResponse } from '../model/getCompletionDetailsResultResponse';
import { GetOverviewResultResponse } from '../model/getOverviewResultResponse';
import { MeasureType } from '../model/measureType';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DashboardsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param startDate 
     * @param endDate 
     * @param wingId 
     * @param squadronId 
     * @param completionLevel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsCompletionDetailsGet(startDate?: Date, endDate?: Date, wingId?: string, squadronId?: string, completionLevel?: CompletionLevelType, observe?: 'body', reportProgress?: boolean): Observable<GetCompletionDetailsResultResponse>;
    public dashboardsCompletionDetailsGet(startDate?: Date, endDate?: Date, wingId?: string, squadronId?: string, completionLevel?: CompletionLevelType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetCompletionDetailsResultResponse>>;
    public dashboardsCompletionDetailsGet(startDate?: Date, endDate?: Date, wingId?: string, squadronId?: string, completionLevel?: CompletionLevelType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetCompletionDetailsResultResponse>>;
    public dashboardsCompletionDetailsGet(startDate?: Date, endDate?: Date, wingId?: string, squadronId?: string, completionLevel?: CompletionLevelType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('StartDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('EndDate', <any>endDate.toISOString());
        }
        if (wingId !== undefined && wingId !== null) {
            queryParameters = queryParameters.set('WingId', <any>wingId);
        }
        if (squadronId !== undefined && squadronId !== null) {
            queryParameters = queryParameters.set('SquadronId', <any>squadronId);
        }
        if (completionLevel !== undefined && completionLevel !== null) {
            queryParameters = queryParameters.set('CompletionLevel', <any>completionLevel);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetCompletionDetailsResultResponse>('get',`${this.basePath}/Dashboards/CompletionDetails`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param wingId 
     * @param squadronId 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsGetActiveUsersGet(wingId?: string, squadronId?: string, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<GetActiveUsersResultListResponse>;
    public dashboardsGetActiveUsersGet(wingId?: string, squadronId?: string, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetActiveUsersResultListResponse>>;
    public dashboardsGetActiveUsersGet(wingId?: string, squadronId?: string, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetActiveUsersResultListResponse>>;
    public dashboardsGetActiveUsersGet(wingId?: string, squadronId?: string, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (wingId !== undefined && wingId !== null) {
            queryParameters = queryParameters.set('WingId', <any>wingId);
        }
        if (squadronId !== undefined && squadronId !== null) {
            queryParameters = queryParameters.set('SquadronId', <any>squadronId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('StartDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('EndDate', <any>endDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetActiveUsersResultListResponse>('get',`${this.basePath}/Dashboards/GetActiveUsers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param startDate 
     * @param endDate 
     * @param wingId 
     * @param squadronId 
     * @param cadetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsGetAverageExperienceTimeGet(startDate?: Date, endDate?: Date, wingId?: string, squadronId?: string, cadetId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dashboardsGetAverageExperienceTimeGet(startDate?: Date, endDate?: Date, wingId?: string, squadronId?: string, cadetId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dashboardsGetAverageExperienceTimeGet(startDate?: Date, endDate?: Date, wingId?: string, squadronId?: string, cadetId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dashboardsGetAverageExperienceTimeGet(startDate?: Date, endDate?: Date, wingId?: string, squadronId?: string, cadetId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('StartDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('EndDate', <any>endDate.toISOString());
        }
        if (wingId !== undefined && wingId !== null) {
            queryParameters = queryParameters.set('WingId', <any>wingId);
        }
        if (squadronId !== undefined && squadronId !== null) {
            queryParameters = queryParameters.set('SquadronId', <any>squadronId);
        }
        if (cadetId !== undefined && cadetId !== null) {
            queryParameters = queryParameters.set('CadetId', <any>cadetId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/Dashboards/GetAverageExperienceTime`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param experienceId 
     * @param startDateTimeUtc 
     * @param endDateTimeUtc 
     * @param wingId 
     * @param squadronId 
     * @param measureType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsGetBestCadetsGet(experienceId: string, startDateTimeUtc?: Date, endDateTimeUtc?: Date, wingId?: string, squadronId?: string, measureType?: MeasureType, observe?: 'body', reportProgress?: boolean): Observable<GetBestCadetsResultResponse>;
    public dashboardsGetBestCadetsGet(experienceId: string, startDateTimeUtc?: Date, endDateTimeUtc?: Date, wingId?: string, squadronId?: string, measureType?: MeasureType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetBestCadetsResultResponse>>;
    public dashboardsGetBestCadetsGet(experienceId: string, startDateTimeUtc?: Date, endDateTimeUtc?: Date, wingId?: string, squadronId?: string, measureType?: MeasureType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetBestCadetsResultResponse>>;
    public dashboardsGetBestCadetsGet(experienceId: string, startDateTimeUtc?: Date, endDateTimeUtc?: Date, wingId?: string, squadronId?: string, measureType?: MeasureType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (experienceId === null || experienceId === undefined) {
            throw new Error('Required parameter experienceId was null or undefined when calling dashboardsGetBestCadetsGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDateTimeUtc !== undefined && startDateTimeUtc !== null) {
            queryParameters = queryParameters.set('StartDateTimeUtc', <any>startDateTimeUtc.toISOString());
        }
        if (endDateTimeUtc !== undefined && endDateTimeUtc !== null) {
            queryParameters = queryParameters.set('EndDateTimeUtc', <any>endDateTimeUtc.toISOString());
        }
        if (wingId !== undefined && wingId !== null) {
            queryParameters = queryParameters.set('WingId', <any>wingId);
        }
        if (squadronId !== undefined && squadronId !== null) {
            queryParameters = queryParameters.set('SquadronId', <any>squadronId);
        }
        if (measureType !== undefined && measureType !== null) {
            queryParameters = queryParameters.set('MeasureType', <any>measureType);
        }
        if (experienceId !== undefined && experienceId !== null) {
            queryParameters = queryParameters.set('ExperienceId', <any>experienceId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetBestCadetsResultResponse>('get',`${this.basePath}/Dashboards/GetBestCadets`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param startDateTimeUtc 
     * @param endDateTimeUtc 
     * @param cadetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsGetCadetOverviewGet(startDateTimeUtc?: Date, endDateTimeUtc?: Date, cadetId?: string, observe?: 'body', reportProgress?: boolean): Observable<GetCadetOverviewResultResponse>;
    public dashboardsGetCadetOverviewGet(startDateTimeUtc?: Date, endDateTimeUtc?: Date, cadetId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetCadetOverviewResultResponse>>;
    public dashboardsGetCadetOverviewGet(startDateTimeUtc?: Date, endDateTimeUtc?: Date, cadetId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetCadetOverviewResultResponse>>;
    public dashboardsGetCadetOverviewGet(startDateTimeUtc?: Date, endDateTimeUtc?: Date, cadetId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDateTimeUtc !== undefined && startDateTimeUtc !== null) {
            queryParameters = queryParameters.set('StartDateTimeUtc', <any>startDateTimeUtc.toISOString());
        }
        if (endDateTimeUtc !== undefined && endDateTimeUtc !== null) {
            queryParameters = queryParameters.set('EndDateTimeUtc', <any>endDateTimeUtc.toISOString());
        }
        if (cadetId !== undefined && cadetId !== null) {
            queryParameters = queryParameters.set('CadetId', <any>cadetId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetCadetOverviewResultResponse>('get',`${this.basePath}/Dashboards/GetCadetOverview`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param startDateTimeUtc 
     * @param endDateTimeUtc 
     * @param wingId 
     * @param squadronId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsGetOverviewGet(startDateTimeUtc?: Date, endDateTimeUtc?: Date, wingId?: string, squadronId?: string, observe?: 'body', reportProgress?: boolean): Observable<GetOverviewResultResponse>;
    public dashboardsGetOverviewGet(startDateTimeUtc?: Date, endDateTimeUtc?: Date, wingId?: string, squadronId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOverviewResultResponse>>;
    public dashboardsGetOverviewGet(startDateTimeUtc?: Date, endDateTimeUtc?: Date, wingId?: string, squadronId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOverviewResultResponse>>;
    public dashboardsGetOverviewGet(startDateTimeUtc?: Date, endDateTimeUtc?: Date, wingId?: string, squadronId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDateTimeUtc !== undefined && startDateTimeUtc !== null) {
            queryParameters = queryParameters.set('StartDateTimeUtc', <any>startDateTimeUtc.toISOString());
        }
        if (endDateTimeUtc !== undefined && endDateTimeUtc !== null) {
            queryParameters = queryParameters.set('EndDateTimeUtc', <any>endDateTimeUtc.toISOString());
        }
        if (wingId !== undefined && wingId !== null) {
            queryParameters = queryParameters.set('WingId', <any>wingId);
        }
        if (squadronId !== undefined && squadronId !== null) {
            queryParameters = queryParameters.set('SquadronId', <any>squadronId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOverviewResultResponse>('get',`${this.basePath}/Dashboards/GetOverview`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
