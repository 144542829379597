import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, Router, withInMemoryScrolling } from '@angular/router';
import { appRoutes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  ApiModule as RPSSModule,
  Configuration as RPSSConfiguration,
  UsersService,
  CohortsService,
  LicenceService,
  NotificationService,
  WingsService,
  SquadronService,
  TrainingRecordsService,
  ImageService,
  ExperienceDownloadsService,
  DashboardsService,
  ExperiencesService,
  NotificationPreferencesService,
  ExperienceTypeService,
  ModulesService,
  ModuleTasksService,
  ActionsService,
  LearningSupportElementsService,
} from '@client-app/core-api';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import {
  MSALInstanceFactory,
  MsalGuardConfigFactory,
  MsalInterceptorConfigFactory,
} from './core/MSAL/msal.config';
import { environment } from '../environments/environment';
import { IconService } from './shared/services/icon.service';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ServerHttpInterceptor } from './core/interceptors/server-http.interceptor';
import { provideNgxStripe } from 'ngx-stripe';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HasRequiredRolesPipe } from './shared/pipes/has-required-role.pipe';

export const appConfig: ApplicationConfig = {
  providers: [

    { provide: UsersService },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideAngularSvgIcon(),
    provideAnimations(),
    provideNgxStripe(environment.STRIPE_PUBLIC_KEY),
    ActionsService,
    IconService,
    CohortsService,
    LicenceService,
    NotificationService,
    WingsService,
    SquadronService,
    TrainingRecordsService,
    ImageService,
    ExperienceDownloadsService,
    DashboardsService,
    ExperiencesService,
    NotificationPreferencesService,
    ExperienceTypeService,
    ModulesService,
    ModuleTasksService,
    LearningSupportElementsService,
    HasRequiredRolesPipe,
    importProvidersFrom(
      RPSSModule.forRoot(
        () =>
          new RPSSConfiguration({
            basePath: environment.apiUrl,
          })
      )
    ),
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      })
    ),
    //MSAL CONFIGURATION
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerHttpInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },

    MsalService,
    MsalGuard,
    MsalBroadcastService,
    //MSAL CONFIGURATION END
  ],
};
