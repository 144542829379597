export const environment = {
  production: true,
  apiUrl: 'https://rpssapi.airforcecadets.net.au',
  portalUrl: 'https://rpss.airforcecadets.net.au',
  msalConfig: {
    auth: {
      clientId: '51424aec-fc65-4791-8651-0fa6edf9c800',
      authority:
        'https://login.microsoftonline.com/58bbd3b8-bbd3-4627-9b82-f17c07de27f2',
      prompt: 'consent',
    },
  },
  apiConfig: {
    scopes: ['user.Read'],
  },
  graphApiUrl: 'https://graph.microsoft.com/v1.0',
  serviceClientId: 'api://6562052c-5ff2-4847-a01b-a8db39bd7be4/.default',
  roles: {
    admin: '16f5b54d-0dee-4b2b-b6f3-d875b188b2d9',
    staff: '902693d3-70ce-412e-96d7-d53839e482f6',
    financialDelegate: 'f1ec64a6-ff40-42e7-9270-05e15cbade6b',
    cadet: '4811b96a-9885-49b5-9943-75945d3eb7aa',
  },
  STRIPE_PUBLIC_KEY: 'pk_live_51HZ95gK4DrPwsqkgLRNH3hzYEkMFEb7IkViYpLY4xOQ5mTGrbGv2m8KzagYytkK4fqibyXgdvJFMsOyT8w9nxLdf0087k4g0yE',
  HELP_LINK:'https://mag.wcoomd.org/uploads/2018/05/blank.pdf',
  videoId:'5HSs4O_nWyc'

};
