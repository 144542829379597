export * from './actions.service';
import { ActionsService } from './actions.service';
export * from './billing.service';
import { BillingService } from './billing.service';
export * from './cohorts.service';
import { CohortsService } from './cohorts.service';
export * from './dashboards.service';
import { DashboardsService } from './dashboards.service';
export * from './experienceDownloads.service';
import { ExperienceDownloadsService } from './experienceDownloads.service';
export * from './experienceType.service';
import { ExperienceTypeService } from './experienceType.service';
export * from './experiences.service';
import { ExperiencesService } from './experiences.service';
export * from './help.service';
import { HelpService } from './help.service';
export * from './image.service';
import { ImageService } from './image.service';
export * from './learningSupportElements.service';
import { LearningSupportElementsService } from './learningSupportElements.service';
export * from './licence.service';
import { LicenceService } from './licence.service';
export * from './maintenance.service';
import { MaintenanceService } from './maintenance.service';
export * from './moduleTasks.service';
import { ModuleTasksService } from './moduleTasks.service';
export * from './modules.service';
import { ModulesService } from './modules.service';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './notificationPreferences.service';
import { NotificationPreferencesService } from './notificationPreferences.service';
export * from './simulatorSessions.service';
import { SimulatorSessionsService } from './simulatorSessions.service';
export * from './squadron.service';
import { SquadronService } from './squadron.service';
export * from './trainingRecords.service';
import { TrainingRecordsService } from './trainingRecords.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './wings.service';
import { WingsService } from './wings.service';
export const APIS = [ActionsService, BillingService, CohortsService, DashboardsService, ExperienceDownloadsService, ExperienceTypeService, ExperiencesService, HelpService, ImageService, LearningSupportElementsService, LicenceService, MaintenanceService, ModuleTasksService, ModulesService, NotificationService, NotificationPreferencesService, SimulatorSessionsService, SquadronService, TrainingRecordsService, UsersService, WingsService];
